import { title } from '@/config';

function welcome() {
    const color = 'rgb(56, 11, 219)';
    const invertedColor = 'rgb(255, 255, 255)';

    const styles = [
        'font-size: 40px',
        `color: ${color}`,
        `border: 1px solid ${invertedColor}`,
        `background-color: ${invertedColor}`,
        'border-radius: 5px',
        'padding: 10px',
    ].join(';');

    console.log(`%c=== ${title} ===`, styles);
}

export default welcome;
